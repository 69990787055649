import React from 'react';
import { graphql, Link } from 'gatsby';
import { Content, Header, Layout } from '../components';
import { Helmet } from 'react-helmet';
import SiteConfig from '../../config/SiteConfig';
import QueryResults from '../models/QueryResults';
import Navigation from '../components/Navigation';
import styled from 'styled-components';
import { Writing } from '../posts';
import { HeaderItem } from '../components/Header';
import PhotoPost from '../models/PhotoPost';
import { PhotoSinglePost } from '../posts/photos/PhotoSinglePost';
import { PhotoGalleryPost } from '../posts/photos/PhotoGalleryPost';

const NavigationArea = styled.div`
  grid-area: navigation;
  position: relative;
`;


interface PostRef {
  // type: string;
  id: string;
  // path: string;
  date: string;
  author?: any;
  photo?: PhotoPost;
  writing?: any;
}

interface MonthData {
  author: QueryResults<any>;
  photos: QueryResults<PhotoPost>;
  writing: QueryResults<any>;
}


interface Props {
  data: MonthData;
  pageContext: {
    date: string;
    name: string;
    months: string[];
    posts: PostRef[];
    prevMonth: string;
  };
}

const TitleDiv = styled.div`
  grid-area: title;
`;

const MoreDiv = styled.div`
  margin: 1rem auto;
  width: 9.2rem;
  padding: 1rem 0 1rem 1rem;
`;

const MoreLink = styled(Link)`
  border-radius: 3px;
  border: 1px solid #e6e6e6;
  padding: 0.8rem 1.5rem;
  background-color: #ffffff;

  &:hover {
    background-color: #d0d8ff;
    color: #001788;
  }
`;


export default class Homepage extends React.Component<Props> {

  private consolidatePosts(data: MonthData) {
    const posts: PostRef[] = [];

    // Add photo data to photo posts
    data.photos && data.photos.edges.forEach((edge) => {
      const photo = edge.node;
      posts.push({
        photo,
        id: photo.id,
        date: photo.date,
        author: data.author.edges[0].node,
      });
    });

    // Add writing data to writing posts
    data.writing && data.writing.edges.forEach((edge) => {
      const writing = edge.node;
      posts.push({
        writing,
        id: writing.id,
        date: writing.frontmatter.sortdate,
      });
    });

    // Sort the posts into a list
    const result = posts.sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      }
      if (a.date > b.date) {
        return -1;
      }
      return 0;
    });

    return result;
  }

  public render() {
    const { data, pageContext } = this.props;
    const { months, prevMonth } = pageContext;

    const posts = this.consolidatePosts(data);

    return (
      <Layout>
        <Helmet title={`Homepage | ${SiteConfig.siteTitle}`}>
          <link href="https://micro.blog/gtch" rel="me" />
        </Helmet>
        <Header linkToHomepage={false} selected={HeaderItem.Everything} />
        <NavigationArea>
          <Navigation selected={months[0]} linkSelected={true} />
        </NavigationArea>

        <TitleDiv>
          <p>Welcome to my website. It's a collection of my photos, writing, opinions, presentations from me, Charles Gutjahr. <Link to={'/about'}>More about me...</Link>
          </p>
        </TitleDiv>

       <Content>
          {posts.map(post => (
            <React.Fragment key={post.id}>
              {post.photo && post.photo.type === 'photo' && (
                <PhotoSinglePost key={post.id} photo={post.photo} author={post.author} />
              )}
              {post.photo && post.photo.type === 'gallery' && (
                <PhotoGalleryPost key={post.id} photo={post.photo} author={post.author} />
              )}
              {post.writing && (
                <Writing
                  title={post.writing.frontmatter.title}
                  date={post.writing.frontmatter.date}
                  excerpt={post.writing.html}
                  timeToRead={post.writing.timeToRead}
                  path={post.writing.fields.path}
                  category={post.writing.frontmatter.category}
                  key={post.writing.fields.slug}
                />
              )}
            </React.Fragment>
          ))}

         <MoreDiv>
           <MoreLink to={`/${prevMonth}`}>Load more</MoreLink>
         </MoreDiv>

       </Content>
      </Layout>
    );
  }
}

export const MonthQuery = graphql`
  query($photoIds: [String], $writingIds: [String]) {
    photos: allPhotosJson(filter: {id: {in: $photoIds }}) {
      totalCount
      edges {
        ...PhotoNode
      }
    }
    author: allPeopleJson(filter: { key: { eq: "charlesgutjahr" }}) {
      edges {
        node {
          key
          name
          profile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 36)
            }
          }
        }
      }
    }
    writing: allMarkdownRemark(
      filter: { id: { in: $writingIds }}
    ) {
      totalCount
      edges {
        node {
          id
          html
          fields {
            slug
            path
          }
          frontmatter {
            title
            date(formatString: "D MMM YYYY")
            sortdate: date(formatString: "YYYY-MM-DDTHH:mm")
            category
          }
          timeToRead
        }
      }
    }
  }
`;
